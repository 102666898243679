var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.title, width: "500px", color: "error" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "30vw", "max-width": "170px", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.close")) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-container", [
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { margin: "0 auto" } },
          _vm._l(_vm.splitMessageLine(_vm.message), function(message, index) {
            return _c("p", { key: index }, [
              _vm._v("\n        " + _vm._s(message) + "\n      ")
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }